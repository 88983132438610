import { PageProps, graphql } from 'gatsby';
import Layout from 'layouts/Layout';

import ResourceDetailLayout from 'components/ResourceDetailLayout';

import type { ContentfulComponentHeading, ContentfulTemplateBlog } from 'graphqlTypes';
import type { FC } from 'react';

interface BlogDataType {
  blogData: ContentfulTemplateBlog;
  cardsHeading: ContentfulComponentHeading;
}

const Page: FC<PageProps<BlogDataType>> = ({ data: { blogData, cardsHeading }, location }) => (
  <Layout slug={location.pathname} seo={blogData.seo}>
    <ResourceDetailLayout
      {...blogData}
      cardsHeading={cardsHeading}
      displayBreadcrumbs={blogData.seo?.displayBreadcrumbs}
    />
  </Layout>
);

export default Page;

export const pageQuery = graphql`
  query templateBlogQuery($slug: String!) {
    blogData: contentfulTemplateBlog(slug: { eq: $slug }) {
      ...contentfulTemplateBlog
    }
    cardsHeading: contentfulComponentHeading(contentful_id: { eq: "Tl24RMI5aVxqSdiqaxUfY" }) {
      ...contentfulComponentHeading
    }
  }
`;
